<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Credenciais de mensageria</span>
          </div>
        </div>

        <div class="wrapper__card__body">
          <div class="wrapper__card__body__form">
            <v-form ref="form">
              <v-tabs v-model="tab" align-with-title fixed-tabs>
                <v-tab>SMS</v-tab>
                <v-tab>E-mail</v-tab>
                <v-tab>Push</v-tab>
                <v-tab>Campanha</v-tab>
                <v-tab>Social</v-tab>
                <v-tab>WhatsApp</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab" style="padding: 10px 1px">
                <v-tab-item key="sms">
                  <Accordion title="Credenciais" class="mb-5">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-select
                            v-model="credentialForm.smsProvider"
                            label="Selecione o provedor de envio"
                            itemText="name"
                            itemValue="value"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :hide-details="true"
                            :items="dataTypes"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipApiKey"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Api Key da Infobip"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipBaseUrl"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Url base da infobip"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.urlShortnerDomain"
                            :hideDetails="true"
                            label="Domínio do encurtador de URL"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Zenvia'
                          "
                        >
                          <z-input
                            v-model="credentialForm.zenviaToken"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Token do Zenvia"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Zenvia'
                          "
                        >
                          <z-input
                            v-model="credentialForm.zenviaKeyword"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Keyword do Zenvia"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Aws'
                          "
                        >
                          <z-input
                            v-model="credentialForm.awsTopicArn"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Tópico ARN"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Aws'
                          "
                        >
                          <z-input
                            v-model="credentialForm.awsAccessKeyId"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Chave de Acesso"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Aws'
                          "
                        >
                          <z-input
                            v-model="credentialForm.awsSecretAccessKey"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Chave de Acesso Secreta"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsProvider === 'Aws'
                          "
                        >
                          <z-input
                            v-model="credentialForm.awsRegion"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Região"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>

                  <Accordion title="Configurações de Optout">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-select
                            v-model="credentialForm.smsOptoutWord"
                            label="Selecione a palavra chave do opt-out"
                            itemText="name"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            itemValue="value"
                            :hide-details="true"
                            :items="smsOptoutWordOptions"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.smsProvider &&
                            credentialForm.smsOptoutWord !== 'Pare' &&
                            credentialForm.smsOptoutWord !== 'Sair'
                          "
                        >
                          <z-input
                            v-model="otherSmsOptoutMessage"
                            :hideDetails="true"
                            label="Cadastrar outra palavra chave para o opt-out"
                            type="text"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.smsProvider"
                        >
                          <z-input
                            v-model="credentialForm.smsOptoutMessage"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Mensagem padrão de opt-out"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.smsProvider"
                        >
                          <z-input
                            v-model="credentialForm.smsOptoutCancelWord"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Digite a palavra chave para cancelar o opt-out"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.smsProvider"
                        >
                          <z-input
                            v-model="credentialForm.smsOptoutComebackMessage"
                            :hideDetails="true"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            label="Mensagem padrão de volta do opt-out"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>

                <v-tab-item key="email">
                  <Accordion title="Credenciais" class="mb-5">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-select
                            v-model="credentialForm.emailProvider"
                            label="Selecione o provedor de envio"
                            itemText="name"
                            itemValue="value"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :hide-details="true"
                            :items="dataTypesEmail"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.emailProvider &&
                            credentialForm.emailProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipEmailApiKey"
                            :hideDetails="true"
                            label="Api Key da Infobip"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.emailProvider &&
                            credentialForm.emailProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipEmailBaseUrl"
                            :hideDetails="true"
                            label="Url base da infobip"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="
                            credentialForm.emailProvider &&
                            credentialForm.emailProvider === 'Sparkpost'
                          "
                        >
                          <z-input
                            v-model="credentialForm.sparkpostApiKey"
                            :hideDetails="true"
                            label="Chave da API do Sparkpost"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.emailProvider"
                        >
                          <z-input
                            v-model="credentialForm.emailSender"
                            :hideDetails="true"
                            label="E-mail de envio"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.emailProvider"
                        >
                          <z-input
                            v-model="credentialForm.emailDomain"
                            :hideDetails="true"
                            label="Dominio de envio de email"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.emailProvider"
                        >
                          <z-input
                            v-model="credentialForm.senderName"
                            :hideDetails="true"
                            label="Nome para envio de email"
                            type="text"
                          />
                        </div>

                        <div
                          class="list__item"
                          v-if="credentialForm.emailProvider"
                        >
                          <z-input
                            v-model="credentialForm.replyTo"
                            :hideDetails="true"
                            label="Responder Para"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>

                <v-tab-item key="push">
                  <Accordion title="Credenciais" class="mb-5">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-select
                            v-model="credentialForm.pushProvider"
                            label="Selecione o provedor de envio"
                            itemText="name"
                            itemValue="value"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :hide-details="true"
                            :items="pushdataTypes"
                            type="text"
                          />
                        </div>

                        <div class="list__item">
                          <z-input
                            v-if="credentialForm.pushProvider === 'firebase'"
                            v-model="credentialForm.firebaseUrl"
                            :hideDetails="true"
                            label="Url do Firebase"
                            type="text"
                          />
                          <z-input
                            v-if="credentialForm.pushProvider === 'onesignal'"
                            v-model="credentialForm.oneSignalAppId"
                            :hideDetails="true"
                            label="App Id"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-if="credentialForm.pushProvider === 'firebase'"
                            v-model="credentialForm.firebaseServiceAccount"
                            :hideDetails="true"
                            label="Json Service Account"
                            type="text"
                            :disabled="
                              credentialForm.firebaseServiceAccount != ''
                            "
                          />
                          <z-input
                            v-if="credentialForm.pushProvider === 'onesignal'"
                            v-model="credentialForm.oneSignalApiKey"
                            :hideDetails="true"
                            label="API Key"
                            type="text"
                          />
                        </div>
                        <div
                          class="list__item__input"
                          v-if="credentialForm.pushProvider === 'firebase'"
                        >
                          <z-btn
                            :isLoading="loadingConnect"
                            text="Escolher arquivo"
                            primary
                            color="primary"
                            @click="openFilePicker()"
                          />
                          <input
                            @change="previewFiles"
                            type="file"
                            ref="file"
                            accept=".json"
                            style="display: none"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>

                <v-tab-item key="campanha">
                  <Accordion title="Utm" class="mb-5">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          Variáveis disponíveis: $channel, $template, $journey
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_source"
                            :hideDetails="true"
                            label="utm_source"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_medium"
                            :hideDetails="true"
                            label="utm_medium"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_campaign"
                            :hideDetails="true"
                            label="utm_campaign"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_id"
                            :hideDetails="true"
                            label="utm_id"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_term"
                            :hideDetails="true"
                            label="utm_term"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.utm_content"
                            :hideDetails="true"
                            label="utm_content"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>

                <v-tab-item key="social">
                  <Accordion class="mb-5" title="Google Ads">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.google_developer_token"
                            :hideDetails="true"
                            label="Google Ads Developer Token"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.google_oauth_client_id"
                            :hideDetails="true"
                            label="Google Ads OAuth Client Id"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.google_oauth_secret_key"
                            :hideDetails="true"
                            label="Google Ads OAuth Secret Key"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.google_oauth_refresh_token"
                            :hideDetails="true"
                            label="Google Ads OAuth Refresh Token"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>

                  <Accordion class="mb-5" title="Facebook">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.fb_ad_account_id"
                            :hideDetails="true"
                            label="Ad Account Id"
                            type="text"
                          />
                        </div>
                        <div class="list__item">
                          <z-input
                            v-model="credentialForm.fb_auth_token"
                            :hideDetails="true"
                            label="Auth Token"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>
                <v-tab-item key="WhatsApp">
                  <Accordion title="WhatsApp" class="mb-5">
                    <template #content>
                      <div class="list">
                        <div class="list__item">
                          <z-select
                            v-model="credentialForm.whatsappProvider"
                            label="Selecione o provedor de envio"
                            itemText="name"
                            itemValue="value"
                            :rules="[(v) => !!v || 'Campo obrigatório']"
                            :hide-details="true"
                            :items="dataTypesWpp"
                            @change="$forceUpdate()"
                            type="text"
                          />
                        </div>
                        <div
                          class="list__item"
                          v-if="
                            credentialForm.whatsappProvider &&
                            credentialForm.whatsappProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipWhatsappApiKey"
                            :hideDetails="true"
                            label="Infobip Whatsapp Api Key"
                            type="text"
                          />
                        </div>
                        <div
                          class="list__item"
                          v-if="
                            credentialForm.whatsappProvider &&
                            credentialForm.whatsappProvider === 'Infobip'
                          "
                        >
                          <z-input
                            v-model="credentialForm.infobipWhatsappBaseUrl"
                            :hideDetails="true"
                            label="Infobip Whatsapp Base Url"
                            type="text"
                          />
                        </div>
                        <div
                          class="list__item"
                          v-if="credentialForm.whatsappProvider"
                        >
                          <z-input
                            v-model="credentialForm.infobipWhatsappSenders"
                            :hideDetails="true"
                            label="Infobip Whatsapp Sender"
                            type="text"
                          />
                        </div>
                      </div>
                    </template>
                  </Accordion>
                </v-tab-item>
              </v-tabs-items>

              <div
                class="list__item actions"
                style="padding-top: 20px !important"
              >
                <v-row justify="end">
                  <z-btn
                    v-if="tab <= 2"
                    :text="tab == 2 ? 'Testar conexão' : 'Testar envio'"
                    :is-loading="loadingSubmit"
                    color="primary"
                    @click="
                      tab === 2 &&
                      credentialForm &&
                      credentialForm.pushProvider === 'onesignal'
                        ? checkTestPushConnection()
                        : (showSendTestSMSModal = true)
                    "
                  />
                  <z-btn
                    @click="clear()"
                    text="Limpar"
                    color="primary"
                    style="margin-left: 10px"
                  />
                  <z-btn
                    style="margin-left: 10px"
                    :isLoading="loadingSubmit"
                    text="Salvar"
                    primary
                    color="primary"
                    @click="submitForm"
                  />
                </v-row>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>

    <z-card-modal
      v-if="showSendTestSMSModal"
      v-model="showSendTestSMSModal"
      width="400"
      :title="varTitle"
    >
      <template #content>
        <div
          class="cards-audience"
          style="gap: 1rem; padding: 1rem 0; flex-wrap: wrap; color: 333333"
        >
          <div>
            <z-input
              v-if="tab === 0"
              v-model="testNumber"
              :mask="'+## (##) #####-####'"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              placeholder="Número"
              type="text"
            />
            <z-input
              v-if="tab === 1"
              v-model="testNumber"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              placeholder="E-mail"
              type="text"
            />
            <z-input
              v-if="tab === 2"
              v-model="testNumber"
              :rules="[(v) => !!v || 'Campo obrigatório']"
              :hideDetails="true"
              placeholder="Token"
              type="text"
            />
          </div>
          <div
            style="display: flex; justify-content: flex-end; margin-top: 8px"
          >
            <z-btn
              text="fechar"
              style="margin-right: 10px"
              :is-loading="loadingTempalte"
              color="primary"
              @click="closeTestModal"
            />
            <z-btn
              text="Enviar"
              primary
              :is-loading="loadingTempalte"
              color="primary"
              @click="sendTestSMS()"
            />
          </div>
        </div>
      </template>
    </z-card-modal>

    <v-dialog v-model="newSenderdialog" width="600">
      <NewSenderModal v-if="newSenderdialog" @close="closeNewSenderModal" />
    </v-dialog>

    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import Accordion from "./components/accordion.vue";
import NewSenderModal from "./components/newSenderModal.vue";
export default {
  components: {
    Accordion,
    NewSenderModal,
  },
  data() {
    return {
      newSenderdialog: false,
      http: new ApiClient(),
      loadingSubmit: false,
      showInformations: false,
      credentialForm: {
        pushProvider: "",
        oneSignalApiKey: "",
        oneSignalAppId: "",
        emailProvider: "",
        smsProvider: "",
        awsTopicArn: "",
        awsRegion: "",
        awsAccessKeyId: "",
        awsSecretAccessKey: "",
        firebaseServiceAccount: "",
        firebaseUrl: "",
        zenviaToken: "",
        zenviaKeyword: "",
        sparkpostApiKey: "",
        sparkpostEmailSender: "",
        emailSender: "",
        infobipApiKey: "",
        infobipEmailApiKey: "",
        infobipEmailBaseUrl: "",
        infobipBaseUrl: "",
        urlShortnerDomain: "",
        smsOptoutMessage: "",
        smsOptoutCancelWord: "",
        smsOptoutComebackMessage: "",
        smsOptoutWord: "",
        fb_ad_account_id: "",
        fb_auth_token: "",
        infobipWhatsappDisplayName: "",
        infobipWhatsappBaseUrl: "",
        infobipWhatsappApiKey: "",
        whatsappBusinessAccountId: "",
        google_developer_token: "",
        google_oauth_client_id: "",
        google_oauth_secret_key: "",
        google_oauth_refresh_token: "",
      },
      otherSmsOptoutMessage: null,
      smsOptoutWordOptions: [
        { name: "Pare", value: "Pare" },
        { name: "Sair", value: "Sair" },
        { name: "Outra", value: "Outra" },
      ],
      dataTypes: [
        { name: "Zenvia", value: "Zenvia" },
        { name: "Aws", value: "Aws" },
        { name: "Infobip", value: "Infobip" },
        { name: "Zoox", value: "Zoox" },
      ],
      dataTypesWpp: [
        { name: "Infobip", value: "Infobip" },
        { name: "Zoox", value: "Zoox" },
      ],
      pushdataTypes: [
        { name: "OneSignal", value: "onesignal" },
        { name: "Firebase", value: "firebase" },
      ],
      dataTypesEmail: [
        { name: "Sparkpost", value: "Sparkpost" },
        { name: "Infobip", value: "Infobip" },
        { name: "Zoox", value: "Zoox" },
        { name: "Zoox Broker", value: "Zoox-Infobip" },
      ],
      testNumber: null,
      showSendTestSMSModal: false,
      tab: null,
      loadingConnect: false,
    };
  },
  computed: {
    varTitle() {
      if (this.tab === 0) return "Enviar SMS de teste";
      else if (this.tab === 1) return "Enviar E-mail de teste";
      else if (this.tab === 2) return "Enviar push de teste";
      else return "";
    },
  },
  created() {
    this.startForm();
  },
  methods: {
    closeNewSenderModal(payload) {
      if (!payload) {
        this.newSenderdialog = false;
        return;
      }

      console.log(payload, "new sender");

      this.newSenderdialog = false;
    },
    clear() {
      this.credentialForm = {
        pushProvider: "",
        oneSignalApiKey: "",
        oneSignalAppId: "",
        smsProvider: "",
        emailProvider: "",
        awsTopicArn: "",
        awsRegion: "",
        awsAccessKeyId: "",
        awsSecretAccessKey: "",
        firebaseServiceAccount: "",
        firebaseUrl: "",
        zenviaToken: "",
        zenviaKeyword: "",
        sparkpostApiKey: "",
        sparkpostEmailSender: "",
        emailSender: "",
        replyTo: "",
        senderName: "",
        emailDomain: "",
        infobipApiKey: "",
        infobipEmailApiKey: "",
        infobipBaseUrl: "",
        infobipEmailBaseUrl: "",
        urlShortnerDomain: "",
        smsOptoutMessage: "",
        smsOptoutCancelWord: "",
        smsOptoutComebackMessage: "",
        smsOptoutWord: "",
        infobipWhatsappSenders: "",
        infobipWhatsappDisplayName: "",
        whatsappBusinessAccountId: "",
        infobipWhatsappBaseUrl: "",
        infobipWhatsappApiKey: "",
        google_developer_token: "",
        google_oauth_client_id: "",
        google_oauth_secret_key: "",
        google_oauth_refresh_token: "",
        whatsappProvider: "",
      };
      this.submitForm();
    },
    closeTestModal() {
      this.showSendTestSMSModal = false;
      this.testNumber = null;
    },
    async checkTestPushConnection() {
      if (
        !this.credentialForm.pushProvider &&
        !(
          this.credentialForm.pushProvider &&
          this.credentialForm.pushProvider === "onesignal" &&
          this.credentialForm.pushProvider.oneSignalApiKey &&
          this.credentialForm.pushProvider.oneSignalAppId
        ) &&
        !(
          this.credentialForm.pushProvider &&
          this.credentialForm.pushProvider === "firebase" &&
          this.credentialForm.pushProvider.firebaseServiceAccount &&
          this.credentialForm.pushProvider.firebaseUrl
        )
      ) {
        this.$toast.error(
          "Preencha as credenciais de envuio para fazer o teste de conexão"
        );
        return;
      }

      return await this.sendTestSMS();
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        try {
          this.loadingSubmit = true;

          let saveForm = { ...this.credentialForm };
          if (
            saveForm.smsOptoutWord === "Outra" &&
            this.otherSmsOptoutMessage
          ) {
            saveForm.smsOptoutWord = this.otherSmsOptoutMessage;
          }

          const response = await this.http.post(
            "messageCredentials/upsert",
            saveForm
          );
          if (response) this.$toast.success("Atualizado com sucesso");

          this.loadingSubmit = false;
        } catch (error) {
          this.$toast.error("Erro ao enviar formulário");
          this.loadingSubmit = false;
        }
      }
    },
    async sendTestSMS() {
      this.loadingSubmit = true;
      if (this.tab === 0) {
        const objToSend = {
          number: this.testNumber,
          ...this.credentialForm,
        };

        await this.http
          .post(`templatesConfiguration/sendSmsTest`, objToSend)
          .then(() => {
            this.$toast.success("SMS de teste enviado com sucesso");
            this.showSendTestSMSModal = false;
            this.testNumber = null;
          })
          .catch((err) => {
            console.log(err);
            this.loadingSubmit = false;
            this.$toast.error("Ocorreu um erro ao enviar o sms");
            throw err;
          });
      } else if (this.tab === 1) {
        const objToSend = {
          number: this.testNumber,
          ...this.credentialForm,
        };

        await this.http
          .post(`templatesConfiguration/sendEmailTest`, objToSend)
          .then((res) => {
            if (res.res)
              this.$toast.success("E-mail de teste enviado com sucesso");
            else this.$toast.error(res.err.detail);
            this.showSendTestSMSModal = false;
            this.testNumber = null;
          })
          .catch((err) => {
            this.loadingSubmit = false;
            this.$toast.error("Ocorreu um erro ao enviar o email");
            throw err;
          });
      } else {
        let objToSend = {
          ...this.credentialForm,
        };

        if (this.credentialForm.pushProvider === "firebase") {
          objToSend.token = this.testNumber;
        }

        await this.http
          .post(`templatesConfiguration/verify-push-connection`, objToSend)
          .then(() => {
            this.$toast.success("Conexão estabelecida com sucesso");
          })
          .catch((err) => {
            this.loadingSubmit = false;
            this.$toast.error("Ocorreu um erro ao enviar a push");
            throw err;
          });
      }
      this.loadingSubmit = false;
    },
    async openFilePicker() {
      this.$refs.file.click();
    },
    async previewFiles(event) {
      console.log(event.target.files);
      // const $context = this;
      this.credentialForm.firebaseServiceAccount = event.target.files[0].name;
      var reader = new FileReader();
      reader.onload = async (e) => {
        this.credentialForm.firebaseServiceAccount = e.target.result;
        console.log(e.target.result);
      };
      reader.readAsText(event.target.files[0]);
    },
    async startForm() {
      try {
        const response = await this.http.get("messageCredentials");
        (this.credentialForm.firebaseServiceAccount =
          response.firebaseServiceAccount),
          (this.credentialForm.firebaseUrl = response.firebaseUrl),
          (this.credentialForm.zenviaToken = response.zenviaToken),
          (this.credentialForm.zenviaKeyword = response.zenviaKeyword),
          (this.credentialForm.sparkpostApiKey = response.sparkpostApiKey),
          (this.credentialForm.sparkpostEmailSender =
            response.sparkpostEmailSender);
        this.credentialForm.emailSender = response.emailSender;
        this.credentialForm.replyTo = response.replyTo;
        this.credentialForm.senderName = response.senderName;
        this.credentialForm.awsAccessKeyId = response.awsAccessKeyId;
        this.credentialForm.awsSecretAccessKey = response.awsSecretAccessKey;
        this.credentialForm.awsRegion = response.awsRegion;
        this.credentialForm.awsTopicArn = response.awsTopicArn;
        this.credentialForm.smsProvider = response.smsProvider;
        this.credentialForm.emailProvider = response.emailProvider;
        this.credentialForm.infobipApiKey = response.infobipApiKey;
        this.credentialForm.infobipEmailApiKey = response.infobipEmailApiKey;
        this.credentialForm.infobipEmailBaseUrl = response.infobipEmailBaseUrl;
        this.credentialForm.infobipBaseUrl = response.infobipBaseUrl;
        this.credentialForm.urlShortnerDomain = response.urlShortnerDomain;
        this.credentialForm.emailDomain = response.emailDomain;
        this.credentialForm.smsOptoutMessage = response.smsOptoutMessage;
        this.credentialForm.smsOptoutCancelWord = response.smsOptoutCancelWord;
        this.credentialForm.smsOptoutWord = response.smsOptoutWord;
        this.credentialForm.smsOptoutComebackMessage =
          response.smsOptoutComebackMessage;

        this.credentialForm.utm_source = response.utm_source;
        this.credentialForm.utm_medium = response.utm_medium;
        this.credentialForm.utm_campaign = response.utm_campaign;
        this.credentialForm.utm_id = response.utm_id;
        this.credentialForm.utm_term = response.utm_term;
        this.credentialForm.utm_content = response.utm_content;
        this.credentialForm.fb_ad_account_id = response.fb_ad_account_id;
        this.credentialForm.fb_auth_token = response.fb_auth_token;

        this.credentialForm.pushProvider = response.pushProvider;
        this.credentialForm.oneSignalApiKey = response.oneSignalApiKey;
        this.credentialForm.oneSignalAppId = response.oneSignalAppId;
        this.credentialForm.infobipWhatsappSenders =
          response.infobipWhatsappSenders;
        this.credentialForm.infobipWhatsappDisplayName =
          response.infobipWhatsappDisplayName;
        this.credentialForm.whatsappBusinessAccountId =
          response.whatsappBusinessAccountId;
        this.credentialForm.google_developer_token =
          response.google_developer_token;
        this.credentialForm.google_oauth_client_id =
          response.google_oauth_client_id;
        this.credentialForm.google_oauth_secret_key =
          response.google_oauth_secret_key;
        this.credentialForm.google_oauth_refresh_token =
          response.google_oauth_refresh_token;
        this.credentialForm.infobipWhatsappBaseUrl =
          response.infobipWhatsappBaseUrl;
        this.credentialForm.infobipWhatsappApiKey =
          response.infobipWhatsappApiKey;

        this.credentialForm.whatsappProvider = response.whatsappProvider;

        if (
          response.smsOptoutWord &&
          response.smsOptoutWord.toLowerCase() !== "pare" &&
          response.smsOptoutWord.toLowerCase() !== "sair" &&
          response.smsOptoutWord.toLowerCase() !== "outra"
        ) {
          this.credentialForm.smsOptoutWord = "Outra";
          this.otherSmsOptoutMessage = response.smsOptoutWord;
        }

        this.showInformations = true;
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro ao inicializar dados");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.v-tab {
  margin-left: 0px !important;
}
.wrapper {
  color: $z-black-1;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    @include trackScrollBar;
    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.3rem;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: $z-s-1 0.5rem;
        & > div {
          &:first-child {
            width: 40%;
          }
        }

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      &__form {
        max-width: 700px;

        .list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: $z-s-1;

          &__item {
            flex: 1 1 500px;
          }

          .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0.5rem;
          }
        }
      }
    }
  }
}
.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
